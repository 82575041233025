<template>
  <b-form-group
    label="Proje No"
    label-for="pnumber"
  >
    <b-form-input
      id="pnumber"
      v-model="dataItem.pnumber"
      placeholder="Otomatik Oluşturulacak"
      disabled
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'ProjectsNoCard',
  components: { BFormInput, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
