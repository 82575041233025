<template>
  <div>
    <b-row>
      <b-col>
        <projects-title-card />
      </b-col>
      <b-col>
        <projects-p-date-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <projects-no-card />
      </b-col>
      <b-col>
        <projects-i-date-card />
      </b-col>
    </b-row>
    <project-area-card />
    <project-door-area-card />
    <b-row>
      <b-col>
        <NotesCard />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import NotesCard from '@/views/Admin/Projects/Elements/Notes.vue'
import ProjectsTitleCard from '@/views/Admin/Projects/Elements/titleCard.vue'
import ProjectsNoCard from '@/views/Admin/Projects/Elements/ProjectNoCard.vue'
import ProjectsIDateCard from '@/views/Admin/Projects/Elements/iDateCard.vue'
import ProjectAreaCard from '@/views/Admin/Projects/Elements/areaCard.vue'
import ProjectDoorAreaCard from '@/views/Admin/Projects/Elements/DoorareaCard.vue'
import ProjectsPDateCard from '@/views/Admin/Projects/Elements/pDateCard.vue'

export default {
  name: 'ProjectForm',
  components: {
    ProjectsPDateCard,
    ProjectDoorAreaCard,
    ProjectAreaCard,
    ProjectsIDateCard,
    ProjectsNoCard,
    ProjectsTitleCard,
    NotesCard,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
}
</script>
