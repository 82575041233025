<template>
  <b-form-group
    label="Görüşme Tarihi"
    label-for="idate"
  >
    <validation-provider
      #default="{ errors }"
      name="Görüşme Tarihi"
      rules="required"
    >
      <b-form-datepicker
        id="idate"
        v-model="dataItem.idate"
        v-bind="{labelNoDateSelected: 'Görüşme Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'ProjectsIDateCard',
  components: { ValidationProvider, BFormGroup, BFormDatepicker },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
