<template>
  <b-form-group
    label="Not"
    label-for="notes"
  >
    <b-form-textarea
      id="notes"
      v-model="dataItem.notes"
      placeholder="Not"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'NotesCard',
  components: { BFormTextarea, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
