<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Alanın En Ölçüsü"
        label-for="x_area"
      >
        <b-input-group append="cm">
          <cleave
            id="x_area"
            v-model="dataItem.x_area"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Alanın Boy Ölçüsü"
        label-for="y_area"
      >
        <b-input-group append="cm">
          <cleave
            id="y_area"
            v-model="dataItem.y_area"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Alanın Yükseklik Ölçüsü"
        label-for="z_area"
      >
        <b-input-group append="cm">
          <cleave
            id="z_area"
            v-model="dataItem.z_area"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>

</template>
<script>
import {
  BFormGroup, BInputGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'
import { localize } from 'vee-validate'

export default {
  name: 'ProjectAreaCard',
  components: {
    BFormGroup,
    BInputGroup,
    Cleave,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
